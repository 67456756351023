import React, { Component } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import "../css/EditObituary.css";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

class EditObituary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table: [],
      updated: false,
    };
    this.deleteObituary = this.deleteObituary.bind(this);
    this.getData = this.getData.bind(this);
  }

  getData() {
    let body = {
      secretToken: Cookies.get("token"),
    };
    axios
      .post(`${process.env.REACT_APP_URL}/checkToken`, body)
      .then((response) => {
        const { email } = response.data.user;
        axios
          .get(`${process.env.REACT_APP_URL}/getAllObituariesForUser/${email}`)
          .then((response) => {
            const table = [];
            response.data.forEach((obituary) => {
              table.push(
                <div className="obituary-row" key={obituary._id + "4"}>
                  <div className="obituary-name" key={obituary._id + "1"}>
                    {obituary.name}
                  </div>
                  <div className="obituary-date" key={obituary._id + "2"}>
                    {moment(obituary.dateOfCreateObituary).format(
                      "DD.MM.YYYY HH:mm"
                    )}
                  </div>
                  <div className="obituary-update" key={obituary._id + "3"}>
                    <a href={`/izmena/${obituary._id}`}>
                      <FontAwesomeIcon size="1x" icon={faEdit} />
                    </a>
                  </div>
                  <div className="obituary-delete">
                    <button
                      className="obituary-edit-button"
                      onClick={this.deleteObituary.bind(this, obituary._id)}
                    >
                      <FontAwesomeIcon size="1x" icon={faTrashAlt} />
                    </button>
                  </div>
                </div>
              );
            });
            this.setState({
              table,
            });
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidUpdate() {
    if (this.state.updated) {
      this.getData();
      this.setState({
        updated: false,
      });
    }
  }

  deleteObituary(id) {
    axios
      .delete(`${process.env.REACT_APP_URL}/delete/${id}`)
      .then((_response) => {
        window.alert("Успешно сте обрисали читуљу.");
        this.setState({
          updated: true,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <div className="form" id="form">
        <div className="container" id="registerUser">
          <h2>Измените читуље</h2> <br />
          <div className="obituary-list">
            <div className="obituary-row">
              <div className="obituary-name">Име</div>
              <div className="obituary-date">Датум креирања</div>
              <div className="obituary-update"></div>
              <div className="obituary-delete"></div>
            </div>
            {this.state.table}
          </div>
        </div>
      </div>
    );
  }
}

export default EditObituary;
