import React from "react";
import "./../App.css";
import "./../css/Pricebook.css";
import "./../css/ObituaryPerCity.css";
import Header from "./Header";
import Footer from "./Footer";

class Pricebook extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <div className="pricebook">
          <h1> ЦЕНОВНИК </h1>

          <div
            style={{ fontSize: "18px", width: "90%", justifyContent: "center" }}
          >
            <br></br>
            <br></br>
            Информацију о смрти, послењи поздрав или помен од сада можете дати
            на неколико начина:
            <div>
              <span style={{ fontWeight: "bold" }}>1)</span> Преко погребних
              предузећа
            </div>
            <div>
              <span style={{ fontWeight: "bold" }}>2)</span> Слањем
              информација(текста и фотографије) на мејл адресу
              citulja.office@gmail.com. <br />
              Након пријема оператер вам шаље инструкције за плаћање,а плаћање
              можете извршити у било којој банци или пошти.
            </div>
          </div>
          <div className="pricebook-page">
            <div className="columns">
              <ul className="price">
                <li className="header1">ФОРМАТ 1/4</li>
                <li className="grey"></li>
                <li>ИМЕ И ПРЕЗИМЕ</li>
                <li>СЛИКА</li>
                <li>ГОДИНА РОЂЕЊА</li>
                <li>ГОДИНА СМРТИ</li>
              </ul>
            </div>
            <div className="columns">
              <ul className="price">
                <li className="header2">ФОРМАТ 1/2</li>
                <li className="grey"></li>
                <li>ИМЕ И ПРЕЗИМЕ</li>
                <li>СЛИКА</li>
                <li>МЕСТО</li>
                <li>ГОДИНА РОЂЕЊА</li>
                <li>ГОДИНА СМРТИ</li>
              </ul>
            </div>
            <div className="columns">
              <ul className="price">
                <li className="header3">ФОРМАТ 1/1</li>
                <li className="grey"></li>
                <li>ИМЕ И ПРЕЗИМЕ</li>
                <li>СЛИКА</li>
                <li>МЕСТО</li>
                <li>ГОДИНА РОЂЕЊА</li>
                <li>ГОДИНА СМРТИ</li>
                <li>ОЖАЛОШЋЕНИ</li>
              </ul>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default Pricebook;
