import React, { Component } from 'react';
import '../css/MentionLSimplified.css'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createBrowserHistory } from 'history';
import { faTimes  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class MentionLSimplified extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      image: ''
    };
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    let image = '/noImage.png';
    if (this.props.image) {
      image = this.props.image;
    } 
    this.setState({
      image: image
    });
  }

  defaultImage(e) {
    e.target.onerror = null; 
    e.target.src='/noImage.png';
  }

  handleClose() {
    this.setState({
      open: false
    });
    createBrowserHistory().push(this.props.url);
  };

  openMention() {
    this.setState({
      open: true
    });
    createBrowserHistory().push("/pomen/" + this.props.id);
  }
  render() {
    return (
      <span>
        <div className='MentionL' onClick={this.openMention.bind(this)}>
          <div className='MentionLContent'>
            <div className="MentionLBox">
              <p className="messageTop"> {this.props.messageTop} </p>
              <img src={this.props.image} onError={this.defaultImage.bind(this)} alt="MentionPhoto" />
              <h3> {this.props.name} </h3>
              <p className="dateOfBirthAndDeath"> {this.props.dateOfBirth} - {this.props.dateOfDeath} </p>
              <p className="messageDown"> {this.props.messageDown} </p>
              <p className="mourners"> {this.props.mourners} </p>
            </div>
            <p id="dateOfPublishing"> {this.props.dateOfCreateObituary} </p>
          </div>
        </div>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth
          fullScreen={window.innerWidth <= 600 ? true : false}
          maxWidth={'md'}
        >
          <DialogActions>
            <button onClick={this.handleClose} className="dialog-button"> <FontAwesomeIcon size="2x" icon={faTimes} /></button>
          </DialogActions>

          <DialogContent>
            <div className='MentionXL'>
              <div className='MentionXLContent'>
                <div className="MentionXLBox">
                  <p className="messageTop_LastGoodbye"> {this.props.messageTop} </p>
                  <img src={this.props.image} onError={this.defaultImage.bind(this)} alt="MentionPhoto" />
                  <h3> {this.props.name} </h3>
                  <p className="dateOfBirthAndDeath"> {this.props.dateOfBirth} - {this.props.dateOfDeath} </p>
                  <p className="messageDown"> {this.props.messageDown} </p>
                  <p className="mourners"> {this.props.mourners} </p>
                </div>
                <p id="dateOfPublishing"> {this.props.dateOfCreateObituary} </p>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </span>
    );
  }

}

export default MentionLSimplified;