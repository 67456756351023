import React, { Component } from "react";
import "../css/ObituaryLSimplified.css";
import "../css/DialogObituary.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { createBrowserHistory } from "history";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "@iconify/react";
import viberIcon from "@iconify-icons/fa-brands/viber";
import waIcon from "@iconify-icons/fa-brands/whatsapp";

class ObituaryLSimplified extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      image: "",
    };
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    let image = "/noImage.png";
    if (this.props.image) {
      image = this.props.image;
    }
    this.setState({
      image: image,
    });
  }

  defaultImage(e) {
    e.target.onerror = null;
    e.target.src = "/noImage.png";
  }

  handleClose() {
    this.setState({
      open: false,
    });
    createBrowserHistory().push(this.props.url);
  }

  openObituary() {
    this.setState({
      open: true,
    });
    createBrowserHistory().push("/citulja/" + this.props.id);
  }

  render() {
    return (
      <span>
        <div className="ObituaryL" onClick={this.openObituary.bind(this)}>
          <div className="ObituaryLContent">
            <div className="ObituaryLBox">
              {this.props.symbol ? (
                <div className="img-symbol">
                  <img
                    onError={this.defaultImage.bind(this)}
                    src={this.props.symbol}
                    alt="Citulja"
                  />
                </div>
              ) : null}
              <img
                onError={this.defaultImage.bind(this)}
                src={this.props.image}
                alt="Citulja"
              />
              <h3> {this.props.name} </h3>
              <p>
                {" "}
                {this.props.dateOfBirth} - {this.props.dateOfDeath}{" "}
              </p>
              <p id="placeFrom"> из {this.props.placeFrom} </p>

              <p> {this.props.message} </p>
              <h3 id="mourners"> ОЖАЛОШЋЕНИ: </h3>
            </div>
            <p id="dateOfPublishing"> {this.props.dateOfCreateObituary} </p>
          </div>
        </div>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth
          fullScreen={window.innerWidth <= 600 ? true : false}
          maxWidth={"md"}
        >
          <DialogActions>
            <button onClick={this.handleClose} className="dialog-button">
              {" "}
              <FontAwesomeIcon size="2x" icon={faTimes} />
            </button>
          </DialogActions>
          <DialogContent>
            <div className="obituary-dialog">
              <div className="obituary-dialog-xl">
                <div className="obituary-dialog-content">
                  <div className="obituary-dialog-box">
                    {this.props.symbol ? (
                      <div className="img-symbol">
                        <img
                          onError={this.defaultImage.bind(this)}
                          src={this.props.symbol}
                          alt="Citulja"
                        />
                      </div>
                    ) : null}
                    <img
                      onError={this.defaultImage.bind(this)}
                      src={this.props.image}
                      alt="Citulja"
                    />
                    <h3> {this.props.name} </h3>
                    <p>
                      {" "}
                      {this.props.dateOfBirth} - {this.props.dateOfDeath}{" "}
                    </p>
                    <p id="placeFrom"> из {this.props.placeFrom} </p>

                    <p> {this.props.message} </p>
                    <div className="mourners">
                      {" "}
                      <span id="title-mourners"> ОЖАЛОШЋЕНИ: </span>{" "}
                      <span id="text-mourners"> {this.props.mourners} </span>{" "}
                    </div>
                    <div className="share" style={{ paddingBottom: "20px" }}>
                      <a href={`viber://forward?text=${window.location.href}`}>
                        <Icon icon={viberIcon} color="black" width="25px" />
                      </a>
                      <a
                        href={`whatsapp://send?text=${window.location.href}`}
                        id="wa"
                      >
                        <Icon icon={waIcon} color="black" width="23px" />
                      </a>
                    </div>
                  </div>
                  <p id="date-of-publishing">
                    {" "}
                    {this.props.dateOfCreateObituary}{" "}
                  </p>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </span>
    );
  }
}

export default ObituaryLSimplified;
