import React from "react";
import "./../App.css";
import "./../css/ObituaryPerCity.css";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import ReactPaginate from "react-paginate";
import ObituarySSimplified from "./ObituarySSimplified";
import ObituaryMSimplified from "./ObituaryMSimplified";
import ObituaryLSimplified from "./ObituaryLSimplified";
import ObituaryXLSimplified from "./ObituaryXLSimplified";
import moment from "moment";
import Last24h from "./Last24h";

class AllObituaries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      obituaries: [],
      allObituaries: [],
      selected: 0,
      pageCount: 0,
    };
  }

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_URL}/getCount`).then((res) => {
      const { numOfPage } = res.data;
      this.setState({
        pageCount: numOfPage,
      });
    });

    this.getData();
  }

  getData() {
    window.scrollTo(0, 0);

    let page = this.state.selected + 1;

    axios
      .get(`${process.env.REACT_APP_URL}/getObituaries/${page}`)
      .then((res) => {
        let allObituaries = [];
        res.data.forEach((d) => {
          allObituaries.push({
            url: this.props.match.url,
            id: d._id,
            name: d.name,
            symbol: d.symbol ? `/${d.symbol}.png` : "",
            dateOfBirth: moment(d.dateOfBirth).format("YYYY"),
            dateOfDeath: moment(d.dateOfDeath).format("DD.MM.YYYY"),
            placeFrom: d.placeFrom,
            image: `${process.env.REACT_APP_URL}/image/${d.image}`,
            size: d.sizeOfObituary,
            message: d.message,
            mourners: d.mourners,
            dateOfCreateObituary: moment(d.dateOfCreateObituary).format(
              "DD.MM.YYYY H:mm"
            ),
          });
        });

        let obituaries = [];
        // const sizes = ['xl', 'l', 'm', 's'];
        // allObituaries.sort((a, b) => {
        //   const sizeA = a.size;
        //   const sizeB = b.size;
        //   const indexA = sizes.indexOf(sizeA);
        //   const indexB = sizes.indexOf(sizeB);
        //   return indexA - indexB;
        // });

        for (let i = 0; i < allObituaries.length; i++) {
          if (allObituaries[i].size === "s") {
            obituaries.push(
              <ObituarySSimplified key={i} {...allObituaries[i]} />
            );
          } else if (allObituaries[i].size === "m") {
            obituaries.push(
              <ObituaryMSimplified key={i} {...allObituaries[i]} />
            );
          } else if (allObituaries[i].size === "l") {
            obituaries.push(
              <ObituaryLSimplified key={i} {...allObituaries[i]} />
            );
          } else if (allObituaries[i].size === "xl") {
            obituaries.push(
              <ObituaryXLSimplified key={i} {...allObituaries[i]} />
            );
          }
        }
        this.setState({
          obituaries,
        });
      });
  }

  handlePageClick = (data) => {
    let { selected } = data;

    this.setState({ selected }, () => {
      this.getData();
    });
  };

  render() {
    return (
      <div>
        <Header />

        <div className="OrbituariesPerCity">
          {this.state.selected + 1 === 1 ? (
            <div>
              <Last24h url={this.props.match.url} />{" "}
              <h2 className="last-24h-title">ПРЕТХОДНИ ПЕРИОД</h2>
            </div>
          ) : null}

          {this.state.obituaries}
        </div>
        <div className="commentBox">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={1}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </div>
        <Footer />
      </div>
    );
  }
}

export default AllObituaries;
