import React from 'react';
import './../App.css';
import './../css/ObituaryPerCity.css';
import Header from './Header';
import Footer from './Footer';
import axios from 'axios';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import MentionMSimplified from './MentionMSimplified';
import MentionLSimplified from './MentionLSimplified';
import MentionXLSimplified from './MentionXLSimplified';

class AllMentions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mentions: [],
      selected: 0,
      pageCount: 0
    };
  }

  getData() {
    let page = this.state.selected + 1;
    axios.get(`${process.env.REACT_APP_URL}/mention/perNum/${page}`)
      .then(res => {
        let allMentions= [];
        res.data.forEach(d => {
          allMentions.push({
            url: this.props.match.url,
            id: d._id,
            name: d.name,
            dateOfBirth: moment(d.dateOfBirth).format('YYYY'),
            dateOfDeath: moment(d.dateOfDeath).format('DD.MM.YYYY'),
            image: d.image,
            size: d.sizeOfMention,
            messageTop: d.messageTop,
            messageDown: d.messageDown,
            mourners: d.mourners,
            dateOfCreateObituary: moment(d.dateOfCreateMention).format('DD.MM.YYYY')
          });
        });

        let mentions = [];
        const sizes = ['xl', 'l', 'm'];
        allMentions.sort((a, b) => {
          const sizeA = a.size;
          const sizeB = b.size;
          const indexA = sizes.indexOf(sizeA);
          const indexB = sizes.indexOf(sizeB);
          return indexA - indexB;
        });

        for(let i = 0; i<allMentions.length; i++) {
          if (allMentions[i].size === "m") {
            mentions.push(<MentionMSimplified key={i} {...allMentions[i]}/>);
          } else if (allMentions[i].size === "l") {
            mentions.push(<MentionLSimplified key={i} {...allMentions[i]}/>);
          } else if (allMentions[i].size === "xl") {
            mentions.push(<MentionXLSimplified key={i} {...allMentions[i]}/>);
          }
        }
        
        this.setState({
          mentions
        })
      });

  }

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_URL}/mention/count`)
      .then(res => {
        const { numOfPage } = res.data;
        this.setState({
          pageCount: numOfPage
        })
      });
      
      this.getData();
  }

  handlePageClick = (data) => {
    let { selected } = data;

    this.setState({ selected }, () => {
      this.getData();
    });
  }

  render() {
    return (
      <div>
        <Header />
        <div className="OrbituariesPerCity">
          {this.state.mentions}
        </div>
        <div className="commentBox">
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={this.state.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={1}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination'}
            activeClassName={'active'}
          />
        </div>
        <Footer />
      </div>
    );
  }
}

export default AllMentions;