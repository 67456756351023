import React, { Component } from "react";
import "../css/Footer.css";

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="footer_top">
          <div className="footer_info">
            <div className="first">
              <h4>ČITULJA.RS - ОБАВЕШТЕЊА O СМРТИ</h4>
              <p>
                {" "}
                Српски портал на којем се сваког дана објављују обавештења o
                смрти из целе Србије. Наш циљ је да олакшамо породицама и
                пријатељима процес објаве саучешћа, захвалница, последњих
                поздрава, сећања и слање телеграма.
              </p>
            </div>
            <div className="second">
              <h4>МАПА САЈТА</h4>
              <ul>
                <li>
                  {" "}
                  <a href="/"> СВЕ ЧИТУЉЕ </a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/"> УМРЛИЦЕ </a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/poslednji-pozdravi"> ПОСЛЕДЊИ ПОЗДРАВИ </a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/pomeni"> ПОМЕНИ </a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/admin"> КРЕИРАЈТЕ ЧИТУЉЕ </a>{" "}
                </li>
              </ul>
            </div>
            <div className="third">
              <h4>КОРИСНИ ЛИНКОВИ</h4>
              <ul>
                <li>
                  {" "}
                  <a href="/kontakt"> КОНТАКТ ИНФОРМАЦИЈЕ </a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/cenovnik"> ЦЕНОВНИК </a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/groblja"> АДРЕСАР ГРОБЉА </a>{" "}
                </li>
                <li>
                  {" "}
                  <a href="/o-nama"> O НАМА </a>{" "}
                </li>
              </ul>
            </div>
            <div className="fourth">
              <h4>ПЛАТИТЕ ОНЛАЈН КАРТИЦОМ</h4>
              <p>Онлајн куповина, ускоро...</p>
              <div>
                <img
                  id="debitcard"
                  src="/mastercard.png"
                  alt="mastercard_photo"
                />
                <img id="debitcard" src="/maestro.png" alt="maestro_photo" />
                <img id="debitcard" src="/visa.gif" alt="visa_photo" />
              </div>
            </div>
          </div>
        </div>
        <div className="footer_down">
          <div className="footer_down_copyright">
            <p>
              © Copyright <a href="/">Citulja.rs</a>
            </p>
          </div>
          <div className="footer_down_img">
            <img src="/logo.png" alt="visa_photo" />
          </div>
          <div className="footer_down_links">
            <a href="/#">Услови коришћења</a>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
