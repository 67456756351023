import React, { Component } from "react";
import "../css/Contact.css";
import Footer from "./Footer";
import Header from "./Header";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faEnvelope,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      message: "",
    };
  }

  handleName(name) {
    this.setState({
      name: name.target.value,
    });
  }

  handleEmail(email) {
    this.setState({
      email: email.target.value,
    });
  }

  handleSubject(subject) {
    this.setState({
      subject: subject.target.value,
    });
  }

  handleMessage(message) {
    this.setState({
      message: message.target.value,
    });
  }

  handleSubmit() {
    const body = this.state;
    axios
      .post(`${process.env.REACT_APP_URL}/send-email`, body)
      .then((_res) => {
        window.alert("Mejl je uspešno poslat");
        this.setState({
          name: "",
          email: "",
          subject: "",
          message: "",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <div className="contact">
        <Header />
        <div className="contact-page">
          <div className="contact-page-left">
            <div className="contact-page-row">
              <div className="contact-page-icon">
                <FontAwesomeIcon size="1x" icon={faMapMarkerAlt} />
              </div>
              <div className="contact-page-row-left">Адреса:</div>
              <div className="contact-page-row-right">Београд, Србија</div>
            </div>

            <div className="contact-page-row">
              <div className="contact-page-icon">
                <FontAwesomeIcon size="1x" icon={faPhoneAlt} />
              </div>
              <div className="contact-page-row-left">Телефон:</div>
              <div className="contact-page-row-right">+381 606239996</div>
            </div>

            <div className="contact-page-row">
              <div className="contact-page-icon">
                <FontAwesomeIcon size="1x" icon={faEnvelope} />
              </div>
              <div className="contact-page-row-left">Мејл адреса:</div>
              <div className="contact-page-row-right">office@citulja.rs</div>
            </div>

            <div className="contact-page-row">
              <div className="contact-page-icon">
                <FontAwesomeIcon size="1x" icon={faClock} />
              </div>
              <div className="contact-page-row-left">Радно време:</div>
              <div className="contact-page-row-right">
                Понедељак - Недеља: 0-24
              </div>
            </div>
          </div>
          <div className="contact-page-right">
            <div className="form-group">
              <label htmlFor="name">Име и презиме:</label>
              <input
                type="text"
                value={this.state.name}
                className="form-control"
                autoComplete="off"
                placeholder="Унесите име и презиме"
                name="name"
                onChange={this.handleName.bind(this)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="email">Мејл адреса:</label>
              <input
                type="text"
                value={this.state.email}
                className="form-control"
                autoComplete="off"
                placeholder="Унесите мејл адресу"
                name="email"
                onChange={this.handleEmail.bind(this)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="subject">Наслов:</label>
              <input
                type="text"
                value={this.state.subject}
                className="form-control"
                autoComplete="off"
                placeholder="Унесите наслов"
                name="subject"
                onChange={this.handleSubject.bind(this)}
              />
            </div>

            <div className="form-group">
              <label htmlFor="message">Порука:</label>
              <textarea
                cols={25}
                row={30}
                type="text"
                value={this.state.message}
                className="form-control"
                id="page-contact-message"
                autoComplete="off"
                placeholder="Унесите поруку"
                name="message"
                onChange={this.handleMessage.bind(this)}
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <button
                type="button"
                onClick={this.handleSubmit.bind(this)}
                className="btn btn-secondary"
                id="page-content-btn"
              >
                Пошаљите
              </button>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Contact;
