import React, { useState, useRef } from "react";
import GoogleMapReact from "google-map-react";
import useSupercluster from "use-supercluster";
import "../css/LocationSearchModal.css";
import mapStyle from "./MapStyle";
import Header from "./Header";
import Footer from "./Footer";
import Groblja from "../groblja.json";

const Marker = ({ children }) => children;

export default function LocationSearchModal() {
  const mapRef = useRef();
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(10);

  //   const url =
  //     "https://data.police.uk/api/crimes-street/all-crime?lat=52.629729&lng=-1.131592&date=2019-10";
  //   const { data, error } = useSwr(url, { fetcher });
  //   const crimes = data && !error ? data.slice(0, 2000) : [];

  const groblja = Groblja;
  const points = groblja.map((groblje) => ({
    type: "Feature",
    properties: {
      cluster: false,
      id: groblje.id,
      city: groblje.city,
      name: groblje.name,
    },
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(groblje.longitude),
        parseFloat(groblje.latitude),
      ],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  return (
    <div style={{ height: "auto" }}>
      <Header />

      <div style={{ height: "100vh", width: "100%", paddingBottom: "60px" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_API_KEY }}
          defaultCenter={{ lat: 44.80285588996411, lng: 20.446457485854758 }}
          defaultZoom={8}
          options={{
            styles: mapStyle,
          }}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map }) => {
            mapRef.current = map;
          }}
          onChange={({ zoom, bounds }) => {
            setZoom(zoom);
            setBounds([
              bounds.nw.lng,
              bounds.se.lat,
              bounds.se.lng,
              bounds.nw.lat,
            ]);
          }}
        >
          {clusters.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster, point_count: pointCount } =
              cluster.properties;

            if (isCluster) {
              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  lat={latitude}
                  lng={longitude}
                >
                  <div
                    className="cluster-marker"
                    style={{
                      width: `${10 + (pointCount / points.length) * 20}px`,
                      height: `${10 + (pointCount / points.length) * 20}px`,
                    }}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        supercluster.getClusterExpansionZoom(cluster.id),
                        20
                      );
                      mapRef.current.setZoom(expansionZoom);
                      mapRef.current.panTo({ lat: latitude, lng: longitude });
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }

            return (
              <Marker
                key={`Id-${cluster.properties.id}`}
                lat={latitude}
                lng={longitude}
              >
                <button className="crime-marker">
                  <img src="/marker.png" alt="groblje" />
                </button>
              </Marker>
            );
          })}
        </GoogleMapReact>
      </div>
      <Footer />
    </div>
  );
}
