import React from "react";
import "./../App.css";
import "./../css/ObituaryPerCity.css";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import ObituarySSimplified from "./ObituarySSimplified";
import ObituaryMSimplified from "./ObituaryMSimplified";
import ObituaryLSimplified from "./ObituaryLSimplified";
import ObituaryXLSimplified from "./ObituaryXLSimplified";
import moment from "moment";

class OrbituariesPerCity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      obituaries: [],
    };
  }
  componentDidMount() {
    const cityName = this.props.match.params.city;
    axios
      .get(`${process.env.REACT_APP_URL}/findByCity/${cityName}`)
      .then((res) => {
        let obituaries = [];
        let i = 0;
        res.data.forEach((d) => {
          i = i + 1;
          if (d.sizeOfObituary === "s") {
            obituaries.push(
              <ObituarySSimplified
                url={this.props.match.url}
                id={d._id}
                key={i}
                name={d.name}
                symbol={d.symbol ? `/${d.symbol}.png` : ""}
                dateOfBirth={moment(d.dateOfBirth).format("YYYY")}
                dateOfDeath={moment(d.dateOfDeath).format("DD.MM.YYYY")}
                image={`${process.env.REACT_APP_URL}/image/${d.image}`}
                message={d.message}
                mourners={d.mourners}
                placeFrom={d.placeFrom}
                dateOfCreateObituary={moment(d.dateOfCreateObituary).format(
                  "DD.MM.YYYY H:mm"
                )}
              />
            );
          } else if (d.sizeOfObituary === "m") {
            obituaries.push(
              <ObituaryMSimplified
                url={this.props.match.url}
                id={d._id}
                key={i}
                name={d.name}
                symbol={d.symbol ? `/${d.symbol}.png` : ""}
                dateOfBirth={moment(d.dateOfBirth).format("YYYY")}
                dateOfDeath={moment(d.dateOfDeath).format("DD.MM.YYYY")}
                image={`${process.env.REACT_APP_URL}/image/${d.image}`}
                placeFrom={d.placeFrom}
                message={d.message}
                mourners={d.mourners}
                dateOfCreateObituary={moment(d.dateOfCreateObituary).format(
                  "DD.MM.YYYY H:mm"
                )}
              />
            );
          } else if (d.sizeOfObituary === "l") {
            obituaries.push(
              <ObituaryLSimplified
                url={this.props.match.url}
                id={d._id}
                key={i}
                name={d.name}
                symbol={d.symbol ? `/${d.symbol}.png` : ""}
                dateOfBirth={moment(d.dateOfBirth).format("YYYY")}
                dateOfDeath={moment(d.dateOfDeath).format("DD.MM.YYYY")}
                image={`${process.env.REACT_APP_URL}/image/${d.image}`}
                message={d.message}
                mourners={d.mourners}
                placeFrom={d.placeFrom}
                dateOfCreateObituary={moment(d.dateOfCreateObituary).format(
                  "DD.MM.YYYY H:mm"
                )}
              />
            );
          } else if (d.sizeOfObituary === "xl") {
            obituaries.push(
              <ObituaryXLSimplified
                url={this.props.match.url}
                id={d._id}
                key={i}
                name={d.name}
                symbol={d.symbol ? `/${d.symbol}.png` : ""}
                dateOfBirth={moment(d.dateOfBirth).format("YYYY")}
                dateOfDeath={moment(d.dateOfDeath).format("DD.MM.YYYY")}
                image={`${process.env.REACT_APP_URL}/image/${d.image}`}
                message={d.message}
                placeFrom={d.placeFrom}
                mourners={d.mourners}
                dateOfCreateObituary={moment(d.dateOfCreateObituary).format(
                  "DD.MM.YYYY H:mm"
                )}
              />
            );
          }
        });
        this.setState({ obituaries });
      });
  }

  render() {
    return (
      <div>
        <Header />
        <div className="OrbituariesPerCity">{this.state.obituaries}</div>
        <Footer />
      </div>
    );
  }
}

export default OrbituariesPerCity;
