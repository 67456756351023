import React, { Component } from 'react';
import axios from 'axios';
import Save from './Save';
import { Redirect } from 'react-router-dom';
import Cookies from 'js-cookie';

class WrapperComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isAuth: true
        };
    }

    componentDidMount() {
        let body = {
            secretToken: Cookies.get("token")
        };
        axios.post(`${process.env.REACT_APP_URL}/checkToken`, body)
            .then((response) => {
                if (response.data.check === true) {
                    this.setState({ isAuth: true });
                } else {
                    this.setState({ isAuth: false });
                }
            })
            .catch((_error) => {
                this.setState({ isAuth: false });
            });
    }

    render() {
        if (this.state.isAuth === null) return null;

        return (this.state.isAuth ? <Save /> : <Redirect to={{ pathname: '/prijavljivanje', state: { from: this.props.location } }} />);
    }
}

export default WrapperComponent;
