import React, { Component } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
// import Citulje from './components/Citulje';
// import SaveObituary from './components/SaveObituary';
import OrbituariesPerCity from "./components/OrbituariesPerCity";
import ObituariesPerName from "./components/ObituariesPerName";
import AllObituaries from "./components/AllObituaries";
import Login from "./components/Login";
import Register from "./components/Register";
import AllMentions from "./components/AllMentions";
import AllLastGoodbye from "./components/AllLastGoodbye";
import LastGoodbye from "./components/LastGoodbye";
import Save from "./components/Save";
// import ResponsiveDialog from './components/Dialog';
import Obituary from "./components/Obituary";
import Verify from "./components/Verify";
import WrapperComponent from "./components/WrapperComponent";
import Mention from "./components/Mention";
import EditObituaryById from "./components/EditObituaryById";
import LocationSearchModal from "./components/LocationSearchModal";
import ReactGA from "react-ga";
import Contact from "./components/Contact";
import Pricebook from "./components/Pricebook";
import AboutUs from "./components/AboutUs";

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route {...rest} render={(props) => <WrapperComponent {...props} />} />
  );
}

ReactGA.initialize(process.env.REACT_APP_TRACKING_ID_UA); // add your tracking id here.

class App extends Component {
  componentDidMount = () =>
    ReactGA.pageview(window.location.pathname + window.location.search);
  componentDidUpdate = () =>
    ReactGA.pageview(window.location.pathname + window.location.search);

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={AllObituaries} />
          <Route path="/citulje" exact component={AllObituaries} />
          <PrivateRoute path="/admin" exact component={Save} />
          <Route path="/prijavljivanje" exact component={Login} />
          <Route path="/registracija" exact component={Register} />
          <Route path="/verifikacija" exact component={Verify} />
          <Route path="/ime/:name" exact component={ObituariesPerName} />
          <Route path="/grad/:city" exact component={OrbituariesPerCity} />
          <Route path="/pomeni" exact component={AllMentions} />
          <Route path="/poslednji-pozdravi" exact component={AllLastGoodbye} />
          <Route path="/citulja/:id" exact component={Obituary} />
          <Route path="/poslednji-pozdrav/:id" exact component={LastGoodbye} />
          <Route path="/pomen/:id" exact component={Mention} />
          <Route path="/izmena/:id" exact component={EditObituaryById} />
          <Route path="/groblja" exact component={LocationSearchModal} />
          <Route path="/kontakt" exact component={Contact} />
          <Route path="/cenovnik" exact component={Pricebook} />
          <Route path="/o-nama" exact component={AboutUs} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
