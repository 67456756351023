import React, { Component } from "react";
import "../css/Header.css";
import { withRouter } from "react-router-dom";
import axios from "axios";

class Header extends Component {
  constructor(init) {
    super(init);
    this.handleChange = this.handleChange.bind(this);
    this.searchPerName = this.searchPerName.bind(this);
    this.state = {
      scrolled: false,
      cities: [],
      otherCities: [],
    };
    this.handleCity = this.handleCity.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      const isTop = window.scrollY < 100;
      const isMinHeight = window.outerWidth > 750;
      if (isTop !== true && isMinHeight) {
        this.setState({ scrolled: true });
      } else if (isMinHeight) {
        this.setState({ scrolled: false });
      }
    });
    axios
      .get(`${process.env.REACT_APP_URL}/mostCommonCity/`)
      .then((res) => {
        let citiesArray = [];
        let otherCities = [];
        res.data.forEach((city) => {
          citiesArray.push(city._id);
        });
        for (let i = 4; i < citiesArray.length; i++) {
          otherCities.push(
            <option key={i} value={citiesArray[i]}>
              {citiesArray[i]}
            </option>
          );
        }

        this.setState({
          cities: citiesArray,
          otherCities,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", () => {});
  }

  handleChange({ target }) {
    let nb = document.getElementById("nav_bar_response");
    if (target.checked) {
      nb.style.display = "block";
    } else {
      nb.style.display = "none";
    }
  }

  searchPerName(e) {
    if (e.key === "Enter") {
      const name = e.target.value;
      if (name !== "") {
        const path = "/ime/" + name;
        this.props.history.push(path);
        this.props.history.go();
      }
    }
  }

  showInput() {
    let input = document.getElementById("name_input");
    input.style.display = "block";
    let city_btn = document.getElementById("city_buttons");
    if (window.innerWidth > 750) {
      city_btn.style.display = "flex";
    } else {
      city_btn.style.display = "block";
    }
  }

  exitInput() {
    let input = document.getElementById("name_input");
    input.style.display = "none";
    let city_btn = document.getElementById("city_buttons");
    city_btn.style.display = "none";
  }

  handleCity(city) {
    window.location.href = "/grad/" + city.target.value;
  }

  render() {
    return (
      <div className="header">
        <div className="btn_response">
          <label htmlFor="check" className="checkbtn">
            <input
              type="checkbox"
              id="check"
              onClick={this.handleChange}
              defaultChecked={this.props.complete}
            />
            <img src="/hamburger.png" className="img_bars" alt="fa_bars"></img>
          </label>
        </div>
        <button onClick={this.showInput} className="input_response">
          <img src="\search.png" alt="spotlight_photo" />
        </button>

        <div
          className={this.state.scrolled ? "first_box_scrolled" : "first_box"}
        >
          <div className="static_bar">
            <ul>
              <li>
                {" "}
                <a href="/kontakt"> Контакт информације </a>{" "}
              </li>
              <li>
                {" "}
                <a href="/cenovnik"> Ценовник </a>{" "}
              </li>
              <li>
                {" "}
                <a href="/groblja" className="static_bar_response">
                  {" "}
                  Адресар гробља{" "}
                </a>{" "}
              </li>
              <li>
                {" "}
                <a href="/o-nama" className="static_bar_response">
                  {" "}
                  О нама{" "}
                </a>{" "}
              </li>
            </ul>
          </div>
        </div>

        <div className="second_box">
          <div className={this.state.scrolled ? "nav_bar_scrolled" : "nav_bar"}>
            <a href="/" className="logo_header">
              {" "}
              <img src="/logo.png" alt="logo_header" />{" "}
            </a>
            <ul>
              <li>
                {" "}
                <a href="/citulje"> СВЕ ЧИТУЉЕ </a>{" "}
              </li>
              <li>
                {" "}
                <a href="/citulje"> УМРЛИЦЕ </a>{" "}
              </li>
              <li>
                {" "}
                <a href="/poslednji-pozdravi"> ПОСЛЕДЊИ ПОЗДРАВИ </a>{" "}
              </li>
              <li>
                {" "}
                <a href="/pomeni"> ПОМЕНИ </a>{" "}
              </li>
              <li>
                <button onClick={this.showInput}>
                  {" "}
                  <img src="\search.png" alt="spotlight_photo" />{" "}
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div
          className={this.state.scrolled ? "name_input_scrolled" : "name_input"}
          id="name_input"
        >
          <input
            placeholder="Unesite ime i prezime"
            onKeyDown={this.searchPerName}
          />

          <button className="exit_input" onClick={this.exitInput}>
            <img src="\icon_close.png" alt="iconClose_photo" />
          </button>
        </div>

        <div className="nav_bar_response" id="nav_bar_response">
          <ul>
            <li>
              {" "}
              <a href="/"> СВЕ ЧИТУЉЕ </a>{" "}
            </li>
            <li>
              {" "}
              <a href="/citulje"> УМРЛИЦЕ </a>{" "}
            </li>
            <li>
              {" "}
              <a href="/poslednji-pozdravi"> ПОСЛЕДЊИ ПОЗДРАВИ </a>{" "}
            </li>
            <li>
              {" "}
              <a href="/pomeni"> ПОМЕНИ </a>{" "}
            </li>
            <li>
              {" "}
              <a href="/kontakt"> КОНТАКТ </a>{" "}
            </li>
            <li>
              {" "}
              <a href="/groblja"> АДРЕСАР ГРОБЉА </a>{" "}
            </li>
            <li>
              {" "}
              <a href="/o-nama"> О НАМА </a>{" "}
            </li>
          </ul>
        </div>

        <div className="city_buttons" id="city_buttons">
          <a className="cities" href={`/grad/${this.state.cities[0]}`}>
            <button className="btn-cities"> {this.state.cities[0]} </button>
          </a>
          <a className="cities" href={`/grad/${this.state.cities[1]}`}>
            <button className="btn-cities"> {this.state.cities[1]} </button>
          </a>
          <a className="cities" href={`/grad/${this.state.cities[2]}`}>
            <button className="btn-cities"> {this.state.cities[2]} </button>
          </a>
          <a className="cities" href={`/grad/${this.state.cities[3]}`}>
            <button className="btn-cities"> {this.state.cities[3]} </button>
          </a>
          <span className="cities">
            <button className="btn-other-cities">
              <select
                name="cities"
                className="other-cities"
                style={{ border: "none" }}
                onChange={this.handleCity.bind(this)}
              >
                <option value="">Ostali</option>
                {this.state.otherCities}
              </select>
            </button>
          </span>
        </div>
      </div>
    );
  }
}
export default withRouter(Header);
