import React, { Component } from 'react';
import '../css/Register.css';
import axios from 'axios';
import Cookies from 'js-cookie';

export default class Registry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                email: '',
                password: '',
                confirmPassword: ''
            }
        };

        this.onEmailChange = this.onEmailChange.bind(this);
        this.onPasswordChange = this.onPasswordChange.bind(this);
        this.onConfirmPasswordChange = this.onConfirmPasswordChange.bind(this); 
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    checkValidityOfEmailAndPassword(entity) {
        const validationEmailRegex = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$");

        if (entity.email === '' || !validationEmailRegex.test(entity.email)) {
            window.alert("Please insert valid email!");
            return false;
        } else if (entity.password === '' || entity.confirmPassword === '') {
            window.alert("Please insert valid password!");
            return false;
        } else if (entity.password !== entity.confirmPassword) {
            window.alert("Password and confirm password not equal");
            return false;
        }

        return true;
    }

    onEmailChange(email) {
        const user = {
            email: email.target.value,
            password: this.state.user.password,
            confirmPassword: this.state.confirmPassword
        }
        this.setState({
            user
        });
    }

    onPasswordChange(password) {
        const user = {
            email: this.state.user.email,
            password: password.target.value,
            confirmPassword: this.state.confirmPassword
        };
        this.setState({
            user
        });
    }

    onConfirmPasswordChange(confirmPassword) {
        const user = {
            email: this.state.user.email,
            password: this.state.user.password,
            confirmPassword: confirmPassword.target.value
        };
        this.setState({
            user
        });
    }

    handleSubmit() {
        const user = this.state.user;
        let valid = this.checkValidityOfEmailAndPassword(user);
        if (!valid) {
            return ;
        }
        const newUser = {
            email: this.state.user.email,
            password: this.state.user.password
        }

        Cookies.set('email', this.state.user.email);
        axios.post(`${process.env.REACT_APP_URL}/sendVerifyToken`, {
            email: user.email
        })
        .then(_res => {
        })
        .catch(err => {
            console.log(err);
        });

        axios.post(`${process.env.REACT_APP_URL}/newUser`, newUser)
        .then(response => 
            window.alert(response.data.msg)
        )
        .catch(err => {
            console.log(err);
        })

        document.getElementById("formUser").reset();
        window.location.href = "/verifikacija";
    }

    render() {
        return (
        <div className="registry">
            <div className="container" id="logInUser">
                <h2>Registracija</h2>
                <form id="formUser">
                    <div className="form-group">
                        <label htmlFor="email">Email:</label>
                        <input type="email" className="form-control" id="emailUser" autoComplete="off" placeholder="Unesite email" name="email" onChange={this.onEmailChange}/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="pwd">Šifra:</label>
                        <input type="password" className="form-control" id="pwd" autoComplete="off" placeholder="Unesite šifru" name="pwd" onChange={this.onPasswordChange}/>
                    </div>

                    <div className="form-group">
                        <label htmlFor="pwd">Potvrdite šifru:</label>
                        <input type="password" className="form-control" id="pwdConfirm" autoComplete="off" placeholder="Unesite šifru" name="pwd" onChange={this.onConfirmPasswordChange}/>
                    </div>

                    <button type="button" onClick={this.handleSubmit} className="btn btn-success">Registrujte se</button>
                </form>
                <p className="goToLogin">Već imate nalog? <a href='/prijavljivanje'>Prijavite se!</a></p>

            </div>
        </div>
    );
  }
}