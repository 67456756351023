import React, { Component } from "react";
import "../css/Last24h.css";
import axios from "axios";
import ObituarySSimplified from "./ObituarySSimplified";
import ObituaryMSimplified from "./ObituaryMSimplified";
import ObituaryLSimplified from "./ObituaryLSimplified";
import ObituaryXLSimplified from "./ObituaryXLSimplified";
import moment from "moment";

class Last24h extends Component {
  constructor(props) {
    super(props);
    this.state = {
      obituaries: [],
    };
  }

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_URL}/last-24h`).then((res) => {
      let allObituaries = [];
      res.data.forEach((d) => {
        allObituaries.push({
          url: this.props.url,
          id: d._id,
          name: d.name,
          dateOfBirth: moment(d.dateOfBirth).format("YYYY"),
          dateOfDeath: moment(d.dateOfDeath).format("DD.MM.YYYY"),
          symbol: d.symbol ? `/${d.symbol}.png` : "",
          placeFrom: d.placeFrom,
          image: `${process.env.REACT_APP_URL}/image/${d.image}`,
          size: d.sizeOfObituary,
          message: d.message,
          mourners: d.mourners,
          dateOfCreateObituary: moment(d.dateOfCreateObituary).format(
            "DD.MM.YYYY H:mm"
          ),
        });
      });

      let obituaries = [];

      for (let i = 0; i < allObituaries.length; i++) {
        if (allObituaries[i].size === "s") {
          obituaries.push(
            <ObituarySSimplified key={i} {...allObituaries[i]} />
          );
        } else if (allObituaries[i].size === "m") {
          obituaries.push(
            <ObituaryMSimplified key={i} {...allObituaries[i]} />
          );
        } else if (allObituaries[i].size === "l") {
          obituaries.push(
            <ObituaryLSimplified key={i} {...allObituaries[i]} />
          );
        } else if (allObituaries[i].size === "xl") {
          obituaries.push(
            <ObituaryXLSimplified key={i} {...allObituaries[i]} />
          );
        }
      }
      this.setState({
        obituaries,
      });
    });
  }

  render() {
    return (
      <div className="last-24h">
        <h2 className="last-24h-title">ПРЕТХОДНИХ 48 САТИ</h2>
        <div>{this.state.obituaries}</div>
      </div>
    );
  }
}

export default Last24h;
