import React, { Component } from 'react';
import '../css/Save.css';
import SaveObituary from './SaveObituary';
import Cookies from 'js-cookie';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import SaveLastGoodbye from './SaveLastGoodbye';
import SaveMention from './SaveMention';
import EditObituary from './EditObituary';

class Save extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabledObituary: true,
            disabledCommemoration: false,
            disabledFinalGreeting: false,
            disabledEditObituary: false,
            disabledEditCommemoration: false,
            disabledEditFinalGreeting: false
        };

        this.showFormForObituary = this.showFormForObituary.bind(this);
        this.showFormForCommemoration = this.showFormForCommemoration.bind(this);
        this.showFormForFinalGreeting = this.showFormForFinalGreeting.bind(this);
        this.showFormForEditObituary = this.showFormForEditObituary.bind(this);
        this.showFormForEditCommemoration = this.showFormForEditCommemoration.bind(this);
        this.showFormForEditFinalGreeting = this.showFormForEditFinalGreeting.bind(this);
        this.logout = this.logout.bind(this);
    }

    showFormForObituary() {
        this.setState({
            disabledObituary: true,
            disabledCommemoration: false,
            disabledFinalGreeting: false,
            disabledEditObituary: false,
            disabledEditCommemoration: false,
            disabledEditFinalGreeting: false
        });

    }

    showFormForCommemoration() {
        this.setState({
            disabledObituary: false,
            disabledCommemoration: true,
            disabledFinalGreeting: false,
            disabledEditObituary: false,
            disabledEditCommemoration: false,
            disabledEditFinalGreeting: false
        });
    }

    showFormForFinalGreeting() {
        this.setState({
            disabledObituary: false,
            disabledCommemoration: false,
            disabledFinalGreeting: true,
            disabledEditObituary: false,
            disabledEditCommemoration: false,
            disabledEditFinalGreeting: false
        });
    }

    showFormForEditObituary() {
        this.setState({
            disabledObituary: false,
            disabledCommemoration: false,
            disabledFinalGreeting: false,
            disabledEditObituary: true,
            disabledEditCommemoration: false,
            disabledEditFinalGreeting: false
        });

    }

    showFormForEditCommemoration() {
        this.setState({
            disabledObituary: false,
            disabledCommemoration: false,
            disabledFinalGreeting: false,
            disabledEditObituary: false,
            disabledEditCommemoration: true,
            disabledEditFinalGreeting: false
        });
    }

    showFormForEditFinalGreeting() {
        this.setState({
            disabledObituary: false,
            disabledCommemoration: false,
            disabledFinalGreeting: false,
            disabledEditObituary: false,
            disabledEditCommemoration: false,
            disabledEditFinalGreeting: true
        });
    }

    logout() {
        Cookies.remove('email');
        Cookies.remove('token');
        window.location.href = "/prijavljivanje";
    }

    render() {
        let typeOfObituary;
        if (this.state.disabledObituary) {
            typeOfObituary = <SaveObituary type="obituary" />
        } else if (this.state.disabledCommemoration) {
            typeOfObituary = <SaveMention />
        } else if (this.state.disabledFinalGreeting) {
            typeOfObituary = <SaveLastGoodbye />
        } else if (this.state.disabledEditObituary) {
            typeOfObituary = <EditObituary />
        }

        return (
            <div className="save">
                <a href='/' className="back"><FontAwesomeIcon icon={faAngleLeft} className="fi_menu" /> Vratite se na početnu</a>
                <button type="button" onClick={this.logout} id="logout" className="btn btn-success">Odjavite se</button>
                <h1 id="titleAdmin">ADMIN PANEL</h1>
                <div className="buttonsInAdminPanel">
                    <button type="button" disabled={this.state.disabledObituary} onClick={this.showFormForObituary} id="btnObituary" className="btn btn-success">Kreirajte čitulju</button>
                    <button type="button" disabled={this.state.disabledCommemoration} onClick={this.showFormForCommemoration} id="btnCommemoration" className="btn btn-success">Kreirajte pomen</button>
                    <button type="button" disabled={this.state.disabledFinalGreeting} onClick={this.showFormForFinalGreeting} id="btnLogInFinalGreeting" className="btn btn-success">Kreirajte poslednji pozdrav</button>
                </div>
                <div className="buttonsInAdminPanel">
                    <button type="button" disabled={this.state.disabledEditObituary} onClick={this.showFormForEditObituary} id="btnObituary" className="btn btn-success">Izmenite čitulju</button>
                    <button type="button" disabled={this.state.disabledEditCommemoration} onClick={this.showFormForEditCommemoration} id="btnCommemoration" className="btn btn-success">Izmenite pomen</button>
                    <button type="button" disabled={this.state.disabledEditFinalGreeting} onClick={this.showFormForEditFinalGreeting} id="btnLogInFinalGreeting" className="btn btn-success">Izmenite poslednji pozdrav</button>
                </div>
                {typeOfObituary}
            </div>
        );
    }
}

export default Save;