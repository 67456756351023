import React, { Component } from 'react';
import axios from 'axios';
import '../css/Verify.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import Cookies from 'js-cookie';

class Verify extends Component {

    constructor(props) {
        super(props);
        this.state = {
            verify: {
                email: '',
                secretToken: ''
            }
        };

        this.onSecretTokenChange = this.onSecretTokenChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    onSecretTokenChange(secretToken) {

        const verify = {
            email: Cookies.get('email'),
            secretToken: secretToken.target.value
        }
        this.setState({
            verify
        });
    }

    handleSubmit() {
        const verify = this.state.verify;
        axios.post(`${process.env.REACT_APP_URL}/verify`, verify)
            .then((response) => {
                window.location.href = response.data.path;
            })
            .catch(err => {
                console.log(err);
            });
    }

    render() {
        return (
            <div className="verify">
                <div className="formular">
                    <div className="back"><a href='/registracija'><FontAwesomeIcon icon={faAngleLeft} className="fi_menu" /> Vratite se na kreiranje naloga</a></div>

                    <div className="container" id="registerUser">
                        <h2 className="titleVerify">Verifikacija naloga</h2>

                        <form id="formAccount">
                            <div className="form-group">
                                <label htmlFor="secretToken">Unesite verafikacioni kod:</label>
                                <input type="secretToken" className="form-control" id="secretToken" autoComplete="off" placeholder="Unesite kod" name="secretToken" onChange={this.onSecretTokenChange} />
                            </div>

                            <button type="button" onClick={this.handleSubmit} id="submitVerify" className="btn btn-success">Potvrdite</button>
                        </form>
                    </div>
                </div>
            </div>

        );
    }
}

export default Verify;