import React, { Component } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import ObituarySSimplified from "./ObituarySSimplified";
import ObituaryMSimplified from "./ObituaryMSimplified";
import ObituaryLSimplified from "./ObituaryLSimplified";
import ObituaryXLSimplified from "./ObituaryXLSimplified";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "../css/SaveObject.css";
import "../css/EditObituaryByid.css";
import Cookies from "js-cookie";

class EditObituaryById extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: "s",
      message: "",
      image: null,
      city: "Ada",
      placeFrom: "",
      name: "",
      symbol: "",
      dateOfBirth: "",
      dateOfDeath: "",
      mourners: "",
      object: null,
      id: "",
      updatedImage: false,
    };

    this.onImageChange = this.onImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSize = this.handleSize.bind(this);
    this.handleCity = this.handleCity.bind(this);
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    axios.get(`${process.env.REACT_APP_URL}/findById/${id}`).then((res) => {
      let image = "/noImage.png";
      if (res.data.image) {
        image = res.data.image;
      }
      this.setState({
        size: res.data.sizeOfObituary,
        name: res.data.name,
        city: res.data.city,
        dateOfBirth: new Date(res.data.dateOfBirth),
        symbol: res.data.symbol,
        image: `${process.env.REACT_APP_URL}/image/${image}`,
        message: res.data.message,
        mourners: res.data.mourners,
        placeFrom: res.data.placeFrom,
        dateOfDeath: new Date(res.data.dateOfDeath),
        imageForShow: `${process.env.REACT_APP_URL}/image/${image}`,
        id: id,
      });
    });
  }

  showObject() {
    let submitButton = document.getElementById("submit");
    submitButton.style.display = "block";
    let object = null;
    let date = new Date();
    const url = `/izmeni/${this.state.id}`;
    if (this.state.size === "s") {
      object = (
        <ObituarySSimplified
          name={this.state.name}
          dateOfBirth={moment(this.state.dateOfBirth).format("YYYY")}
          dateOfDeath={moment(this.state.dateOfDeath).format("DD.MM.YYYY")}
          placeFrom={this.state.placeFrom}
          symbol={this.state.symbol ? `/${this.state.symbol}.png` : ""}
          image={this.state.imageForShow}
          message={this.state.message}
          mourners={this.state.mourners}
          dateOfCreateObituary={moment(date).format("DD.MM.YYYY")}
          url={url}
        />
      );
    } else if (this.state.size === "m") {
      object = (
        <ObituaryMSimplified
          name={this.state.name}
          dateOfBirth={moment(this.state.dateOfBirth).format("YYYY")}
          dateOfDeath={moment(this.state.dateOfDeath).format("DD.MM.YYYY")}
          symbol={this.state.symbol ? `/${this.state.symbol}.png` : ""}
          placeFrom={this.state.placeFrom}
          image={this.state.imageForShow}
          message={this.state.message}
          mourners={this.state.mourners}
          dateOfCreateObituary={moment(date).format("DD.MM.YYYY")}
          url={url}
        />
      );
    } else if (this.state.size === "l") {
      object = (
        <ObituaryLSimplified
          name={this.state.name}
          dateOfBirth={moment(this.state.dateOfBirth).format("YYYY")}
          dateOfDeath={moment(this.state.dateOfDeath).format("DD.MM.YYYY")}
          symbol={this.state.symbol ? `/${this.state.symbol}.png` : ""}
          placeFrom={this.state.placeFrom}
          image={this.state.imageForShow}
          message={this.state.message}
          mourners={this.state.mourners}
          dateOfCreateObituary={moment(date).format("DD.MM.YYYY")}
          url={url}
        />
      );
    } else if (this.state.size === "xl") {
      object = (
        <ObituaryXLSimplified
          name={this.state.name}
          dateOfBirth={moment(this.state.dateOfBirth).format("YYYY")}
          dateOfDeath={moment(this.state.dateOfDeath).format("DD.MM.YYYY")}
          symbol={this.state.symbol ? `/${this.state.symbol}.png` : ""}
          placeFrom={this.state.placeFrom}
          image={this.state.imageForShow}
          message={this.state.message}
          mourners={this.state.mourners}
          dateOfCreateObituary={moment(date).format("DD.MM.YYYY")}
          url={url}
        />
      );
    }

    this.setState({ object });
  }

  handleChange(nameOfInput, event) {
    if (nameOfInput === "name") {
      this.setState({ name: event.target.value });
    } else if (nameOfInput === "messageTop") {
      this.setState({ messageTop: event.target.value });
    } else if (nameOfInput === "messageDown") {
      this.setState({ messageDown: event.target.value });
    } else if (nameOfInput === "message") {
      this.setState({ message: event.target.value });
    } else if (nameOfInput === "placeFrom") {
      this.setState({ placeFrom: event.target.value });
    } else {
      this.setState({ mourners: event.target.value });
    }
  }

  onImageChange(event) {
    let file = event.target.files[0];

    this.setState({
      imageForShow: URL.createObjectURL(file),
      image: file,
      updatedImage: true,
    });
  }

  handleDateOfBirth = (date) => {
    this.setState({
      dateOfBirth: date,
    });
  };

  handleDateOfDeath = (date) => {
    this.setState({
      dateOfDeath: date,
    });
  };

  handleSize(size) {
    this.setState({
      size: size.target.value,
    });
  }

  handleCity(city) {
    this.setState({
      city: city.target.value,
    });
  }

  handleSymbol(symbol) {
    this.setState({
      symbol: symbol.target.value,
    });
  }

  defaultImage(e) {
    e.target.onerror = null;
    e.target.src = "/noImage.png";
  }

  handleSubmit() {
    let data = new FormData();
    if (
      this.state.name !== "" &&
      this.state.dateOfBirth !== "" &&
      this.state.dateOfDeath !== "" &&
      this.state.mourners !== "" &&
      this.state.message !== ""
    ) {
      let body = {
        secretToken: Cookies.get("token"),
      };
      axios
        .post(`${process.env.REACT_APP_URL}/checkToken`, body)
        .then((response) => {
          data.append("image", this.state.image);
          data.append("name", this.state.name);
          data.append(
            "dateOfBirth",
            moment(this.state.dateOfBirth).format("YYYY-MM-DD")
          );
          data.append(
            "dateOfDeath",
            moment(this.state.dateOfDeath).format("YYYY-MM-DD")
          );
          data.append("id", this.state.id);
          data.append("mourners", this.state.mourners);
          data.append("symbol", this.state.symbol);
          data.append("createdByUser", response.data.user.email);
          data.append("city", this.state.city);
          data.append("message", this.state.message);
          data.append("size", this.state.size);
          data.append("placeFrom", this.state.placeFrom);
          data.append("updatedImage", this.state.updatedImage);

          axios
            .post(`${process.env.REACT_APP_URL}/update`, data)
            .then((_response) => {
              window.alert("Uspešno ste promenili čitulju");
              window.location.href = "/admin";
            })
            .catch((error) => {
              console.log(error);
              window.alert("Došlo je do greške");
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      window.alert("Nisu uneti svi potrebni podaci");
    }
  }

  render() {
    let { object } = this.state;
    return (
      <div className="form" id="form">
        <div className="container" id="registerUser">
          <h2>Измените читуље</h2> <br />
          <form id="formUser">
            <div className="form-group">
              <label htmlFor="size">Величина читуље:</label>
              <select
                className="browser-default custom-select"
                name="size"
                id="size"
                onChange={this.handleSize.bind(this)}
              >
                <option value={this.state.size}>{this.state.size}</option>
                <option value="s">S</option>
                <option value="m">M</option>
                <option value="l">L</option>
                <option value="xl">XL</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="size">Симбол:</label>
              <select
                className="browser-default custom-select"
                name="symbol"
                id="symbol"
                onChange={this.handleSymbol.bind(this)}
              >
                <option value={this.state.symbol}>{this.state.symbol}</option>
                <option value="zvezda">Звезда</option>
                <option value="krst">Крст</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="name">Име и презиме:</label>
              <input
                type="text"
                value={this.state.name}
                className="form-control"
                autoComplete="off"
                placeholder="Унесите име"
                name="name"
                onChange={this.handleChange.bind(this, "name")}
              />
            </div>

            <div className="form-group">
              <label htmlFor="city">Град:</label>
              <select
                className="browser-default custom-select"
                name="city"
                id="city"
                onChange={this.handleCity.bind(this)}
              >
                <option value={this.state.city}>{this.state.city}</option>
                <option value="Ada">Ada </option>
                <option value="Aleksandrovac">Aleksandrovac </option>
                <option value="Aleksinac">Aleksinac </option>
                <option value="Alibunar">Alibunar </option>
                <option value="Apatin">Apatin </option>
                <option value="Aranđelovac">Aranđelovac </option>
                <option value="Arilje">Arilje </option>
                <option value="Babušnica">Babušnica </option>
                <option value="Bajina Bašta">Bajina Bašta </option>
                <option value="Bajmok">Bajmok </option>
                <option value="Banatsko  Novo Selo">Banatsko Novo Selo </option>
                <option value="Barajevo">Barajevo </option>
                <option value="Barič">Barič </option>
                <option value="Batočina">Batočina </option>
                <option value="Bač">Bač </option>
                <option value="Bačka Palanka">Bačka Palanka </option>
                <option value="Bačka Topola">Bačka Topola </option>
                <option value="Bački Jarak">Bački Jarak </option>
                <option value="Bački Petrovac">Bački Petrovac </option>
                <option value="Bačko Petrovo">Bačko Petrovo </option>
                <option value="Bačko Petrovo Selo">Bačko Petrovo Selo </option>
                <option value="Bela Crkva">Bela Crkva </option>
                <option value="Bela Palanka">Bela Palanka </option>
                <option value="Beograd">Beograd </option>
                <option value="Beočin">Beočin </option>
                <option value="Bečej">Bečej </option>
                <option value="Beška">Beška </option>
                <option value="Blace">Blace </option>
                <option value="Bogatić">Bogatić </option>
                <option value="Bojnik">Bojnik </option>
                <option value="Boljevac">Boljevac </option>
                <option value="Bor">Bor </option>
                <option value="Bosilegrad">Bosilegrad </option>
                <option value="Brus">Brus </option>
                <option value="Bujanovac">Bujanovac </option>
                <option value="Crepaja">Crepaja </option>
                <option value="Crvenka">Crvenka </option>
                <option value="Despotovac">Despotovac </option>
                <option value="Dimitrovgrad">Dimitrovgrad </option>
                <option value="Doljevac">Doljevac </option>
                <option value="Dolovo">Dolovo </option>
                <option value="Futog">Futog </option>
                <option value="Golubac">Golubac </option>
                <option value="Gornji Milanovac">Gornji Milanovac </option>
                <option value="Grocka">Grocka </option>
                <option value="Inđija">Inđija </option>
                <option value="Irig">Irig </option>
                <option value="Ivanjica">Ivanjica </option>
                <option value="Jagodina">Jagodina </option>
                <option value="Kanjiža">Kanjiža </option>
                <option value="Kać">Kać </option>
                <option value="Kikinda">Kikinda </option>
                <option value="Kladovo">Kladovo </option>
                <option value="Knić">Knić </option>
                <option value="Knjaževac">Knjaževac </option>
                <option value="Koceljeva">Koceljeva </option>
                <option value="Kosjerić">Kosjerić </option>
                <option value="Kosovska Mitrovica">Kosovska Mitrovica </option>
                <option value="Kostolac">Kostolac </option>
                <option value="Kovačica">Kovačica </option>
                <option value="Kovilj">Kovilj </option>
                <option value="Kovin">Kovin </option>
                <option value="Kragujevac">Kragujevac </option>
                <option value="Kraljevo">Kraljevo </option>
                <option value="Krupanj">Krupanj </option>
                <option value="Kruševac">Kruševac </option>
                <option value="Kula">Kula </option>
                <option value="Kupinovo">Kupinovo </option>
                <option value="Kuršumlija">Kuršumlija </option>
                <option value="Kučevo">Kučevo </option>
                <option value="Lajkovac">Lajkovac </option>
                <option value="Lapovo">Lapovo </option>
                <option value="Lazarevac">Lazarevac </option>
                <option value="Lebane">Lebane </option>
                <option value="Leposavić">Leposavić </option>
                <option value="Leskovac">Leskovac </option>
                <option value="Ljig">Ljig </option>
                <option value="Ljubovija">Ljubovija </option>
                <option value="Loznica">Loznica </option>
                <option value="Majdanpek">Majdanpek </option>
                <option value="Majur">Majur </option>
                <option value="Mali Iđoš">Mali Iđoš </option>
                <option value="Mali Zvornik">Mali Zvornik </option>
                <option value="Mionica">Mionica </option>
                <option value="Mladenovac">Mladenovac </option>
                <option value="Mol">Mol </option>
                <option value="Negotin">Negotin </option>
                <option value="Niš">Niš </option>
                <option value="Niška">Niška Banja </option>
                <option value="Nova Crnja">Nova Crnja </option>
                <option value="Nova Pazova">Nova Pazova </option>
                <option value="Nova Varoš">Nova Varoš </option>
                <option value="Novi Banovci">Novi Banovci </option>
                <option value="Novi Bečej">Novi Bečej </option>
                <option value="Novi Kneževac">Novi Kneževac </option>
                <option value="Novi Pazar">Novi Pazar </option>
                <option value="Novi Sad">Novi Sad </option>
                <option value="Obrenovac">Obrenovac </option>
                <option value="Odžaci">Odžaci </option>
                <option value="Opovo">Opovo </option>
                <option value="Pančevo">Pančevo </option>
                <option value="Paraćin">Paraćin </option>
                <option value="Petrovac">Petrovac </option>
                <option value="Petrovac na Mlavi">Petrovac na Mlavi </option>
                <option value="Petrovaradin">Petrovaradin </option>
                <option value="Pećinci">Pećinci </option>
                <option value="Pirot">Pirot </option>
                <option value="Plandište">Plandište </option>
                <option value="Platičevo">Platičevo </option>
                <option value="Požarevac">Požarevac </option>
                <option value="Požega">Požega </option>
                <option value="Preševo">Preševo </option>
                <option value="Priboj">Priboj </option>
                <option value="Prijepolje">Prijepolje </option>
                <option value="Prokuplje">Prokuplje </option>
                <option value="Rakovica">Rakovica </option>
                <option value="Rača">Rača </option>
                <option value="Raška">Raška </option>
                <option value="Rekovac">Rekovac </option>
                <option value="Ruma">Ruma </option>
                <option value="Rumenka">Rumenka </option>
                <option value="Senta">Senta </option>
                <option value="Sečanj">Sečanj </option>
                <option value="Sivac">Sivac </option>
                <option value="Sjenica">Sjenica </option>
                <option value="Smedervska Palanka">Smedervska Palanka </option>
                <option value="Smederevo">Smederevo </option>
                <option value="Sokobanja">Sokobanja </option>
                <option value="Sombor">Sombor </option>
                <option value="Sopot">Sopot </option>
                <option value="Srbobran">Srbobran </option>
                <option value="Sremska Kamenica">Sremska Kamenica </option>
                <option value="Sremska Mitrovica">Sremska Mitrovica </option>
                <option value="Sremski Karlovci">Sremski Karlovci </option>
                <option value="Stara Pazova">Stara Pazova </option>
                <option value="Stari Banovci">Stari Banovci </option>
                <option value="Starčevo">Starčevo </option>
                <option value="Subotica">Subotica </option>
                <option value="Surdulica">Surdulica </option>
                <option value="Surčin">Surčin </option>
                <option value="Svilajnac">Svilajnac </option>
                <option value="Svrljig">Svrljig </option>
                <option value="Temerin">Temerin </option>
                <option value="Titel">Titel </option>
                <option value="Topola">Topola </option>
                <option value="Trstenik">Trstenik </option>
                <option value="Tutin">Tutin </option>
                <option value="Ub">Ub </option>
                <option value="Užice">Užice </option>
                <option value="Valjevo">Valjevo </option>
                <option value="Varvarin">Varvarin </option>
                <option value="Velika Plana">Velika Plana </option>
                <option value="Veliko Gradište">Veliko Gradište </option>
                <option value="Veternik">Veternik </option>
                <option value="Vladimirci">Vladimirci </option>
                <option value="Vladičin Han">Vladičin Han </option>
                <option value="Vlasotince">Vlasotince </option>
                <option value="Vranje">Vranje </option>
                <option value="Vrbas">Vrbas </option>
                <option value="Vrnjačka Banja">Vrnjačka Banja </option>
                <option value="Vrčin">Vrčin </option>
                <option value="Vršac">Vršac </option>
                <option value="Zaječar">Zaječar </option>
                <option value="Zemun">Zemun </option>
                <option value="Zrenjanin">Zrenjanin </option>
                <option value="Ćićevac">Ćićevac </option>
                <option value="Ćuprija">Ćuprija </option>
                <option value="Čajetina">Čajetina </option>
                <option value="Čačak">Čačak </option>
                <option value="Čenej">Čenej </option>
                <option value="Čoka">Čoka </option>
                <option value="Čukarica">Čukarica </option>
                <option value="Čurug">Čurug </option>
                <option value="Šabac">Šabac </option>
                <option value="Šid">Šid </option>
                <option value="Šimanovci">Šimanovci </option>
                <option value="Žabalj">Žabalj </option>
                <option value="Žabari">Žabari </option>
                <option value="Žagubica">Žagubica </option>
                <option value="Žitište">Žitište </option>
                <option value="Žitorađa">Žitorađa </option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="dataBirth">Датум rođenja:</label> <br />
              <DatePicker
                className="form-control"
                name="dataBirth"
                selected={this.state.dateOfBirth}
                onChange={this.handleDateOfBirth}
                autoComplete="off"
              />
            </div>

            <div className="form-group">
              <label htmlFor="dataDeath">Датум smrti:</label> <br />
              <DatePicker
                className="form-control"
                name="dataDeath"
                selected={this.state.dateOfDeath}
                onChange={this.handleDateOfDeath}
                autoComplete="off"
              />
            </div>

            <div className="form-group">
              <label htmlFor="placeFrom">Iz mesta:</label> <br />
              <input
                type="text"
                value={this.state.placeFrom}
                className="form-control"
                autoComplete="off"
                placeholder="Unesite mesto/naselje pokojnika"
                name="placeFrom"
                onChange={this.handleChange.bind(this, "placeFrom")}
              />
            </div>

            <div className="form-group">
              <label htmlFor="message">Порука:</label>
              <input
                type="text"
                value={this.state.message}
                className="form-control"
                autoComplete="off"
                placeholder="Unesite poruku"
                name="message"
                onChange={this.handleChange.bind(this, "message")}
              />
            </div>

            <div className="form-group">
              <label htmlFor="mourners">ОЖАЛОШЋЕНИ:</label>
              <input
                type="text"
                value={this.state.mourners}
                className="form-control"
                autoComplete="off"
                placeholder="Unesite ožalošćene"
                name="mourners"
                onChange={this.handleChange.bind(this, "mourners")}
              />
            </div>
            <div className="obituary-photo">
              <img
                src={this.state.imageForShow}
                onError={this.defaultImage.bind(this)}
                alt="imageObituary"
              />
            </div>
            <div className="form-group">
              <label htmlFor="image">Fotografija</label> <br />
              <input
                type="file"
                onChange={this.onImageChange}
                className=""
                id="group_image"
              />
            </div>
            <br />

            <a href="#submit">
              <button
                type="button"
                id="button"
                onClick={this.showObject.bind(this)}
                className="btn btn-success"
              >
                Прикажи читуљу
              </button>
            </a>
            <div>{object}</div>

            <button
              id="submit"
              type="button"
              onClick={this.handleSubmit}
              className="btn btn-success"
            >
              Сачувајте
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default EditObituaryById;
