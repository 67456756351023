import React, { Component } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "../css/SaveObject.css";
import LastGoodbyeLSimplified from "./LastGoodbyeLSimplified";
import LastGoodbyeMSimplified from "./LastGoodbyeMSimplified";
import LastGoodbyeXLSimplified from "./LastGoodbyeXLSimplified";

class SaveLastGoodbye extends Component {
  constructor(props) {
    super(props);
    this.state = {
      size: "m",
      messageDown: "",
      image: null,
      imageForShow: null,
      name: "",
      dateOfBirth: "",
      dateOfDeath: "",
      messageTop: "",
      mourners: "",
      object: null,
    };

    this.onImageChange = this.onImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showObject = this.showObject.bind(this);
    this.handleSize = this.handleSize.bind(this);
    this.handleCity = this.handleCity.bind(this);
  }

  handleChange(nameOfInput, event) {
    if (nameOfInput === "name") {
      this.setState({ name: event.target.value });
    } else if (nameOfInput === "messageTop") {
      this.setState({ messageTop: event.target.value });
    } else if (nameOfInput === "messageDown") {
      this.setState({ messageDown: event.target.value });
    } else {
      this.setState({ mourners: event.target.value });
    }
  }

  onImageChange(event) {
    let file = event.target.files[0];
    this.setState({
      image: file,
      imageForShow: URL.createObjectURL(event.target.files[0]),
    });
  }

  handleDateOfBirth = (date) => {
    this.setState({
      dateOfBirth: date,
    });
  };

  handleDateOfDeath = (date) => {
    this.setState({
      dateOfDeath: date,
    });
  };

  handleSize(size) {
    this.setState({
      size: size.target.value,
    });
  }

  handleCity(city) {
    this.setState({
      city: city.target.value,
    });
  }

  showObject() {
    let submitButton = document.getElementById("submit");
    submitButton.style.display = "block";
    let object = "";
    let date = new Date();
    const url = "/admin";

    if (this.state.size === "m") {
      object = (
        <LastGoodbyeMSimplified
          name={this.state.name}
          dateOfBirth={moment(this.state.dateOfBirth).format("YYYY")}
          dateOfDeath={moment(this.state.dateOfDeath).format("DD.MM.YYYY")}
          image={this.state.imageForShow}
          messageTop={this.state.messageTop}
          messageDown={this.state.messageDown}
          mourners={this.state.mourners}
          dateOfCreateObituary={moment(date).format("DD.MM.YYYY")}
          url={url}
        />
      );
    } else if (this.state.size === "l") {
      object = (
        <LastGoodbyeLSimplified
          name={this.state.name}
          dateOfBirth={moment(this.state.dateOfBirth).format("YYYY")}
          dateOfDeath={moment(this.state.dateOfDeath).format("DD.MM.YYYY")}
          image={this.state.imageForShow}
          messageTop={this.state.messageTop}
          messageDown={this.state.messageDown}
          mourners={this.state.mourners}
          dateOfCreateObituary={moment(date).format("DD.MM.YYYY")}
          url={url}
        />
      );
    } else if (this.state.size === "xl") {
      object = (
        <LastGoodbyeXLSimplified
          name={this.state.name}
          dateOfBirth={moment(this.state.dateOfBirth).format("YYYY")}
          dateOfDeath={moment(this.state.dateOfDeath).format("DD.MM.YYYY")}
          image={this.state.imageForShow}
          messageTop={this.state.messageTop}
          messageDown={this.state.messageDown}
          mourners={this.state.mourners}
          dateOfCreateObituary={moment(date).format("DD.MM.YYYY")}
          url={url}
        />
      );
    }

    this.setState({ object });
  }

  handleSubmit() {
    let data = new FormData();
    if (
      this.state.name !== "" &&
      this.state.dateOfBirth !== "" &&
      this.state.dateOfDeath !== "" &&
      this.state.messageDown !== "" &&
      this.state.messageTop !== ""
    ) {
      data.append("image", this.state.image);
      data.append("name", this.state.name);
      data.append(
        "dateOfBirth",
        moment(this.state.dateOfBirth).format("YYYY-MM-DD")
      );
      data.append(
        "dateOfDeath",
        moment(this.state.dateOfDeath).format("YYYY-MM-DD")
      );
      data.append("messageDown", this.state.messageDown);
      data.append("messageTop", this.state.messageTop);
      data.append("mourners", this.state.mourners);
      data.append("size", this.state.size);
      axios
        .post(`${process.env.REACT_APP_URL}/lastGoodbye/save`, data)
        .then((response) => {
          window.alert(response.data);
          this.setState({
            size: "m",
            messageDown: "",
            image: null,
            imageForShow: null,
            name: "",
            dateOfBirth: "",
            dateOfDeath: "",
            messageTop: "",
            mourners: "",
            object: null,
          });
        })
        .catch((error) => {
          console.log(error);
          window.alert("Došlo je do greške");
        });
    } else {
      window.alert("Nisu uneti svi potrebni podaci");
    }
  }

  render() {
    let { object } = this.state;
    return (
      <div className="form" id="form">
        <div className="container" id="registerUser">
          <h2>Poslednji pozdrav</h2> <br />
          <form id="formUser">
            <div className="form-group">
              <label htmlFor="size">Veličina:</label>
              <select
                className="browser-default custom-select"
                name="size"
                id="size"
                onChange={this.handleSize.bind(this)}
              >
                <option value="m">M</option>
                <option value="l">L</option>
                <option value="xl">XL</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="name">Порука изнад слике:</label>
              <input
                type="text"
                value={this.state.messageTop}
                className="form-control"
                autoComplete="off"
                placeholder="Унесите поруку"
                name="name"
                onChange={this.handleChange.bind(this, "messageTop")}
              />
            </div>

            <div className="form-group">
              <label htmlFor="image">Фотографија:</label> <br />
              <input
                type="file"
                onChange={this.onImageChange}
                className=""
                id="group_image"
              />
            </div>

            <div className="form-group">
              <label htmlFor="name">Име и презиме:</label>
              <input
                type="text"
                value={this.state.name}
                className="form-control"
                autoComplete="off"
                placeholder="Unesite ime i prezime"
                name="name"
                onChange={this.handleChange.bind(this, "name")}
              />
            </div>

            <div className="form-group">
              <label htmlFor="dataBirth">Датум рођења:</label> <br />
              <DatePicker
                className="form-control"
                name="dataBirth"
                selected={this.state.dateOfBirth}
                onChange={this.handleDateOfBirth}
                autoComplete="off"
              />
            </div>

            <div className="form-group">
              <label htmlFor="dataDeath">Датум smrti:</label> <br />
              <DatePicker
                className="form-control"
                name="dataDeath"
                selected={this.state.dateOfDeath}
                onChange={this.handleDateOfDeath}
                autoComplete="off"
              />
            </div>

            <div className="form-group">
              <label htmlFor="message">Порука испод слике:</label>
              <input
                type="text"
                value={this.state.message}
                className="form-control"
                autoComplete="off"
                placeholder="Unesite poruku"
                name="message"
                onChange={this.handleChange.bind(this, "messageDown")}
              />
            </div>

            <div className="form-group">
              <label htmlFor="mourners">ОЖАЛОШЋЕНИ:</label>
              <input
                type="text"
                value={this.state.mourners}
                className="form-control"
                autoComplete="off"
                placeholder="Unesite ožalošćene"
                name="mourners"
                onChange={this.handleChange.bind(this, "mourners")}
              />
            </div>

            <br />
            <a href="#submit">
              <button
                type="button"
                id="button"
                onClick={this.showObject}
                className="btn btn-success"
              >
                Prikaži poslednji pozdrav
              </button>
            </a>
            <div>{object}</div>
            <button
              id="submit"
              type="button"
              onClick={this.handleSubmit}
              className="btn btn-success"
            >
              Sačuvajte
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default SaveLastGoodbye;
