import React, { Component } from "react";
import "../css/Obituary.css";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import "../css/MentionXLSimplified.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

class LastGoodbye extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      dateOfBirth: "",
      dateOfDeath: "",
      messageTop: "",
      messageDown: "",
      mourners: "",
      image: null,
      open: false,
    };
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  openLastGoodbye() {
    this.setState({
      open: true,
    });
  }

  defaultImage() {
    this.setState({
      image: "/noImage.png",
    });
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    axios
      .get(`${process.env.REACT_APP_URL}/lastGoodbye/findById/${id}`)
      .then((res) => {
        let image = "/noImage.png";
        if (res.data.image) {
          image = res.data.image;
        }
        this.setState({
          name: res.data.name,
          messageDown: res.data.messageDown,
          dateOfBirth: moment(res.data.dateOfBirth).format("YYYY"),
          image: image,
          messageTop: res.data.messageTop,
          mourners: res.data.mourners,
          dateOfDeath: moment(res.data.dateOfDeath).format("DD.MM.YYYY"),
          dateOfCreateObituary: res.data.dateOfCreateObituary,
        });
      });
  }

  render() {
    return (
      <div>
        <Header />
        <div className="OrbituariesPerCity">
          <span>
            <div
              className="MentionXL"
              onClick={this.openLastGoodbye.bind(this)}
            >
              <div className="MentionXLContent">
                <div className="MentionXLBox">
                  <p className="messageTop_LastGoodbye">
                    {" "}
                    {this.state.messageTop}{" "}
                  </p>
                  <img
                    src={this.state.image}
                    onError={this.defaultImage.bind(this)}
                    alt="MentionPhoto"
                  />
                  <h3> {this.state.name} </h3>
                  <p className="dateOfBirthAndDeath">
                    {" "}
                    {this.state.dateOfBirth} - {this.props.dateOfDeath}{" "}
                  </p>
                  <p className="messageDown"> {this.state.messageDown} </p>
                  <p className="mourners"> {this.state.mourners} </p>
                </div>
                <p id="dateOfPublishing"> {this.state.dateOfCreateObituary} </p>
              </div>
            </div>
            <Dialog
              open={this.state.open}
              onClose={this.handleClose}
              aria-labelledby="responsive-dialog-title"
              fullWidth
              fullScreen={window.innerWidth <= 600 ? true : false}
              maxWidth={"md"}
            >
              <DialogActions>
                <button onClick={this.handleClose} className="dialog-button">
                  {" "}
                  <FontAwesomeIcon size="2x" icon={faTimes} />
                </button>
              </DialogActions>

              <DialogContent>
                <div className="MentionXL">
                  <div className="MentionXLContent">
                    <div className="MentionXLBox">
                      <p className="messageTop_LastGoodbye">
                        {" "}
                        {this.state.messageTop}{" "}
                      </p>
                      <img
                        src={this.state.image}
                        onError={this.defaultImage.bind(this)}
                        alt="MentionPhoto"
                      />
                      <h3> {this.state.name} </h3>
                      <p className="dateOfBirthAndDeath">
                        {" "}
                        {this.state.dateOfBirth} - {this.state.dateOfDeath}{" "}
                      </p>
                      <p className="messageDown"> {this.state.messageDown} </p>
                      <p className="mourners"> {this.state.mourners} </p>
                    </div>
                    <p id="dateOfPublishing">
                      {" "}
                      {this.state.dateOfCreateObituary}{" "}
                    </p>
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </span>
        </div>
        <Footer />
      </div>
    );
  }
}

export default LastGoodbye;
