import React from "react";
import "./../css/AboutUs.css";
import Header from "./Header";
import Footer from "./Footer";

class AboutUs extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <h1
          style={{
            paddingTop: "200px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {" "}
          О НАМА{" "}
        </h1>
        <div className="about-us">
          <div className="about-us-text">
            Citulja.rs је српски портал на којем се сваког дана објављују
            информације о смрти из целе Србије.
          </div>
          <div className="about-us-text">
            Уколико не купујете дневне новине, тренутно нисте у земљи или живите
            у иностранству, може се догодити да информације о смрти Вашег
            рођака, пријатеља или познаника не дођу на време до Вас.
          </div>
          <div className="about-us-text">
            Наш циљ је да олакшамо породицама и пријатељима процес објаве
            информација о смрти, саучешћа, последњих поздрава, помена и слање
            телеграма.
          </div>
          <div className="about-us-text">
            У нади да ће бити од велике помоћи, овде можете сазнати и локацију
            гробља, обичаје и остале сервисне информације везане за последњи
            испраћај наших најмилијих.
          </div>
          <div className="about-us-text">
            Наша мисија је да створимо централизовано место успомена на наше
            најмилије.
            <br />
            <br />
            <br />
          </div>
          <div className="about-us-text">
            За сва питања и информације стојимо Вам на располагању.
            <br />
            <br />
            <br />
          </div>

          <div className="about-us-text">+381 606239996</div>

          <div className="about-us-text">office@citulja.rs</div>
          <div className="about-us-text">www.citulja.rs</div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default AboutUs;
