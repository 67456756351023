import React, { Component } from "react";
import "../css/MentionMSimplified.css";
import "../css/LastGoodbyeSimplified.css";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { createBrowserHistory } from "history";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class LastGoodbyeMSimplified extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      image: "",
    };
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    let image = "/noImage.png";
    if (this.props.image) {
      image = this.props.image;
    }
    this.setState({
      image: image,
    });
  }

  defaultImage(e) {
    e.target.onerror = null;
    e.target.src = "/noImage.png";
  }

  handleClose() {
    this.setState({
      open: false,
    });
    createBrowserHistory().push(this.props.url);
  }

  openLastGoodbye() {
    this.setState({
      open: true,
    });
    createBrowserHistory().push("/poslednji-pozdrav/" + this.props.id);
  }

  render() {
    return (
      <span>
        <div className="MentionM" onClick={this.openLastGoodbye.bind(this)}>
          <div className="MentionMContent">
            <div className="MentionMBox">
              <p className="messageTop_LastGoodbye">
                {" "}
                {this.props.messageTop}{" "}
              </p>
              <img
                src={this.props.image}
                onError={this.defaultImage.bind(this)}
                alt="MentionPhoto"
              />
              <h4> {this.props.name} </h4>
              <p className="messageDown"> {this.props.messageDown} </p>
              <p className="mourners"> {this.props.mourners} </p>
            </div>
            <p id="dateOfPublishing"> {this.props.dateOfCreateObituary} </p>
          </div>
        </div>

        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
          fullWidth
          fullScreen={window.innerWidth <= 600 ? true : false}
          maxWidth={"md"}
        >
          <DialogActions>
            <button onClick={this.handleClose} className="dialog-button">
              {" "}
              <FontAwesomeIcon size="2x" icon={faTimes} />
            </button>
          </DialogActions>

          <DialogContent>
            <div className="MentionXL">
              <div className="MentionXLContent">
                <div className="MentionXLBox">
                  <p className="messageTop_LastGoodbye">
                    {" "}
                    {this.props.messageTop}{" "}
                  </p>
                  <img
                    src={this.props.image}
                    onError={this.defaultImage.bind(this)}
                    alt="MentionPhoto"
                  />
                  <h3> {this.props.name} </h3>
                  <p className="dateOfBirthAndDeath">
                    {" "}
                    {this.props.dateOfBirth} - {this.props.dateOfDeath}{" "}
                  </p>
                  <p className="messageDown"> {this.props.messageDown} </p>
                  <p className="mourners"> {this.props.mourners} </p>
                </div>
                <p id="dateOfPublishing"> {this.props.dateOfCreateObituary} </p>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </span>
    );
  }
}

export default LastGoodbyeMSimplified;
