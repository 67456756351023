import React, { Component } from "react";
import "../css/Obituary.css";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import "../css/DialogObituary.css";
import { Icon } from "@iconify/react";
import viberIcon from "@iconify-icons/fa-brands/viber";
import waIcon from "@iconify-icons/fa-brands/whatsapp";
import moment from "moment";

class Obituary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      city: "Ada",
      dateOfBirth: "",
      dateOfDeath: "",
      message: "",
      mourners: "",
      image: null,
      open: false,
      viber: "",
      wa: "",
    };
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.setState({
      open: false,
    });
  }

  openObituary() {
    this.setState({
      open: true,
    });
  }

  defaultImage() {
    this.setState({
      image: "/noImage.png",
    });
  }

  componentDidMount() {
    const viber = `viber://forward?text=${window.location.href}`;
    const wa = `whatsapp://send?text=${window.location.href}`;
    const id = this.props.match.params.id;
    axios.get(`${process.env.REACT_APP_URL}/findById/${id}`).then((res) => {
      let image = "/noImage.png";
      if (res.data.image) {
        image = `${process.env.REACT_APP_URL}/image/${res.data.image}`;
      }
      this.setState({
        name: res.data.name,
        city: res.data.city,
        dateOfBirth: moment(res.data.dateOfBirth).format("YYYY"),
        image: image,
        message: res.data.message,
        mourners: res.data.mourners,
        dateOfDeath: moment(res.data.dateOfDeath).format("DD.MM.YYYY"),
        dateOfCreateObituary: moment(res.data.dateOfCreateObituary).format(
          "DD.MM.YYYY H:mm"
        ),
        viber,
        wa,
      });
    });
  }

  render() {
    return (
      <div>
        <Header />
        <div className="OrbituariesPerCity">
          <span>
            <div
              className="ObituaryXL"
              href="city"
              onClick={this.openObituary.bind(this)}
              style={{ cursor: "auto" }}
            >
              <div className="ObituaryXLContent">
                <div className="ObituaryXLBox" id="ObituaryXLBox">
                  <img
                    onError={this.defaultImage.bind(this)}
                    src={this.state.image}
                    alt="Citulja"
                  />
                  <h3> {this.state.name} </h3>
                  <p>
                    {" "}
                    {this.state.dateOfBirth} - {this.state.dateOfDeath}{" "}
                  </p>
                  <p> {this.state.message} </p>
                  <div className="mourners">
                    {" "}
                    <span id="titleMourners"> ОЖАЛОШЋЕНИ: </span>{" "}
                    <span id="textMourners"> {this.state.mourners} </span>{" "}
                  </div>
                  <div className="share">
                    <a href={this.state.viber}>
                      <Icon icon={viberIcon} color="black" width="25px" />
                    </a>
                    <a href={this.state.wa} id="wa">
                      <Icon icon={waIcon} color="black" width="23px" />
                    </a>
                  </div>
                </div>

                <p id="dateOfPublishing"> {this.state.dateOfCreateObituary} </p>
              </div>
            </div>
          </span>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Obituary;
